.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.TeamPhoto {
  max-width: calc(100% - 40px);
  margin: 20px;
}

.Section {
  display: grid;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Section-item {
  padding: 0px;
}

@media only screen and (min-width: 750px) {
  .s1 {
    grid-template-columns: [l1] 1fr [le];
  }

  .s2 {
    grid-template-columns: [l1] 1fr [s2-l2] 1fr [le];
  }

  .s3 {
    grid-template-columns: [l1] 1fr [s3-l2] 1fr [s3-l3] 1fr [le];
  }

  .Section-item {
    padding: 3px;
  }
}

@media only screen and (min-width: 1200px) {
  .s1 {
    grid-template-columns: [l1] 1200px [le];
  }

  .s2 {
    grid-template-columns: [l1] 600px [s2-l2] 600px [le];
  }

  .s3 {
    grid-template-columns: [l1] 400px [s3-l2] 400px [s3-l3] 400px [le];
  }

  .s6 {
    grid-template-columns: [l1] 200px [s6-l2] 200px [s6-l3] 200px [s6-l4] 200px [s6-l5] 200px [s6-l6] 200px [le];
  }

  .s6 {
    grid-template-columns: [l1] 240px [s5-l2] 240px [s5-l3] 240px [s5-l4] 240px [s5-l5] 240px [le];
  }

  .Section-item {
    padding: 5px;
  }
}

.Section-centered {
  grid-column-start: l1;
  grid-column-end: le;
  justify-self: center;
  align-self: center;
}

.Text-center {
  text-align: center;
}

.Text-sp {
  color: var(--sp-color);
}

.Text-primary {
  color: var(--sp-prim-color);
}

.Bg-light {
  background-color: var(--sp-light-color);
}

.Primary {
  background-color: var(--sp-color);
  color: var(--sp-prim-color);
}

.Button {
  background-color: var(--sp-color);
  color: var(--sp-prim-color);
  padding: 10px;
  border-radius: 4px;
  border: 0px;
  margin: 2px;
  min-width: 140px;
}

.Socials-button {
  min-width: 65px;
  min-height: 65px;
  border-radius: 12px;
  display: grid;
  grid-template-columns: [l1] 40px [l2] auto [le];
  align-content: center;
  justify-content: center;
}

.Socials-name {
  font-size: 20px;
  padding-left: 6px;
  padding-right: 4px;
  text-align: center;
  align-self: center;
  display: none;
}

@media only screen and (min-width: 1200px) {
  .Socials-name {
    display: block;
  }
}

.Button:hover {
  filter: brightness(110%);
  cursor: pointer;
}

.Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectThumb-image {
  max-width: min(500px, 100%);
}

.ProjectThumb {
  border: solid var(--sp-color) 1px;
  border-radius: 8px;
  padding: 20px;
  background-color: var(--sp-light-color);
  color: var(--sp-color);
}

.ProjectThumb:hover {
  filter: brightness(110%);
}

.Footer {
  margin-top: 15px;
  border-top: solid var(--sp-color) 1px;
  padding: 10px;
}