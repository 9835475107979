.Carousel {
  position: relative;
  display: grid;
  background-color: black;
  z-index: 0;
}

.Carousel-image {
  width: 100%;
}

.Carousel-item {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-self: center;
  align-self: center;
}

.Carousel-button {
  padding: 8px;
  color: white;
  text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px rgb(0, 0, 0), 0 0 50px rgb(0, 0, 0);
  width: 60px;
  text-align: center;
  cursor: pointer;
}

.Carousel-overlay {
  position: absolute;
}

.Carousel-left {
  left: 10px;
  bottom: 10px;
}

.Carousel-right {
  right: 10px;
  bottom: 10px;
}

.Carousel-center {
  top: 10px;
  right: calc(50% - 30px);
}

.Carousel-bottom {
  bottom: 10px;
  text-align: center;
  width: 100%;
}

.Carousel-dot {
  color: gray;
}

.Carousel-dot.Carousel-current {
  color: white;
}

.Carousel-dots {
  display: grid;
  grid-template-columns: repeat( auto-fit, 20px );
  grid-auto-flow: row;
  justify-content: center;
  align-content: center;
  column-gap: 2px;
}