.Header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

.Header > * > h1 {
  text-shadow: 0 0 30px rgb(0, 0, 0);
}

.Header > * > h5 {
  text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px rgb(0, 0, 0);
}

.Header > * > p {
  text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px rgb(0, 0, 0), 0 0 50px rgb(0, 0, 0);
}

@media only screen and (min-width: 750px) {
  .Header > .Section-centered {
    width: 700px;
  }
}