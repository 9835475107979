.NavBar {
  background-color: #282c34;
  height: 80px;
  position: fixed;
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: [l1] 1fr [s2-l2] 1fr [le];
  justify-content: center;
  z-index: 1;
}

.NavBar-placeholder {
  height: 80px;
}

.NavBar-brand {
  grid-column-start: l1;
  grid-column-end: s2-l2;
}

.NavBar-navigation {
  grid-column-start: s2-l2;
  grid-column-end: le;
  justify-self: right;
  list-style-type: none;
  list-style-position: outside;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.NavBar-navigation-trigger {
  float: left;
  padding: 10px;
  color: lightgray;
  visibility: visible;
}

.NavBar-navigation-trigger:hover {
  color: white;
  cursor: pointer;
}

.NavBar-navigation-links.HamborgirOpen {
  position: fixed;
  top: 80px;
  left: 0px;
  width: 100%;
}

.NavBar-navigation-item {
  display: none;
  float: left;
  padding: 10px;
  color: lightgray;
}

.NavBar-navigation-item:hover {
  color: white;
  cursor: pointer;
}

.NavBar-navigation-links.HamborgirOpen > * > .NavBar-navigation-item {
  display: inline;
  width: 100%;
  text-align: center;
  background-color: #353a44;
  border-bottom: #282c34 solid 1px;
  font-size: x-large;
  padding: 30px;
  text-align: center;

}

.NavBar-overlay {
  position: fixed;
  background: black;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  opacity: 0.8;
}

@media only screen and (min-width: 750px) {
  .NavBar-navigation-links.HamborgirOpen {
    position: static;
    width: auto;
  }

  .NavBar-navigation-links.HamborgirOpen > * > .NavBar-navigation-item {
    width: auto;
    padding: 10px;
    background-color: #282c34;
    font-size: medium;
  }

  .NavBar-navigation-trigger {
    display: none;
  }

  .NavBar-navigation-item {
    display: inline;
  }

  .NavBar-overlay {
    display:none;
  }
}

@media only screen and (min-width: 1200px) {
  .NavBar {
    grid-template-columns: [l1] 600px [s2-l2] 600px [le];
  }
}